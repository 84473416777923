import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Label, Search } from 'semantic-ui-react';
import _ from 'lodash';

// Custom components
import TableHeader from 'components/atoms/Table/tableHeader';
import AddReportModal from 'components/modals/Membership/MembershipReports/addReportModal';
import PaginationShorthand from "components/atoms/Pager";
import { PAGE_SIZE_FOR_PAGINATION, getCurrentlyShowingItemsInGrid, ItemsPerPage } from 'helpers/utilCommon';
import RenderSelect from 'components/atoms/Select';
import { MEMBERSHIPREPORTSHEADERINFO, getTempSortKey, SORTINGKEYS, sortingAfterSave } from 'models/Membership/membershipReportsModel';
import { getMembershipReportListAction, getReportTypeListAction } from 'actions/Membership/membershipReportsAction';
import useCallbackState from 'hooks/useCallbackState';


const MembershipReportsList = () => {
    
    const [isLoading, setIsLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [pageSize, setPageSize] = useState(PAGE_SIZE_FOR_PAGINATION);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [currentlyShowingItems, setCurrentlyShowingItems] = useState(false);
    const [sortKey, setSortKey] = useState({});
    const [reportList, setReportList] = useState([]);
    const [activePageData, setActivePageData] = useState([]);
    const [searchReportInput, setSearchReportInput] = useState('');
    const [filteredData, setFilterData] = useState([]);
    const [enableFilter, setEnableFilter] = useCallbackState(false);
    const [storeSearchResult, setStoreSearchResult] = useState([]);
    const [typeReportList, setReportTypeList] = useState([]);
    const [isSaveReport, setIsSaveReport] = useState(false);

    const dispatch = useDispatch();
    const props = useSelector((state) => {
        return {
            userPermission: state.commonReducer.userPermission,
            initialValues: state.reportsReducer.initialValues ? state.reportsReducer.initialValues : undefined,
            messageCodes: _.get(state.i18nReducer.dictionaries, state.i18nReducer.currentLanguage),
        };
    });

    const loadInitialList = (searchInputValue = searchReportInput, isRecordAdded = false) => {
        getMembershipReportListAction(dispatch, (response) => {
            !_.isEmpty(response) && response?.ReportTypeList.length > 0 ? setReportTypeList(response.ReportTypeList) : null;
            if( _.isEmpty(sortKey) && !isRecordAdded) {
                changeListDataOnPagination(pageSize, pageNumber, response.ReportList, enableFilter, searchInputValue)
            }else{
                const tempSortKey = isRecordAdded ? sortingAfterSave : getTempSortKey(sortKey);
                if(isRecordAdded){
                    setSortKey('');
                    setIsSaveReport(true);
                }
                sortReportData(response.ReportList, tempSortKey.sortColumn, tempSortKey.sortOrder, searchInputValue);
            }
        });
    }

    useEffect(() => {
        loadInitialList()
    }, []);


    const onChangeNumberOfItemsPerPage = (e, value) => {
        changeListDataOnPagination(value, 1, reportList);
        setPageSize(value);
        setPageNumber(1);
    }

    const updateCurrentlyShowingItems = (totalItems, pageSize, pageNumber = 1) => {
        const currentlyShowingItems = getCurrentlyShowingItemsInGrid(pageNumber, pageSize, totalItems);
        setCurrentlyShowingItems(currentlyShowingItems);
    }

    const changeListDataOnPagination = (size, number, result = reportList, filterApply = enableFilter, searchInputValue= searchReportInput) => {
        setActivePageData([]);
        let results = (enableFilter && !_.isEmpty(searchInputValue)) ? storeSearchResult: result;
        const pageSize = size > 0 ? Number(size) : PAGE_SIZE_FOR_PAGINATION;
        const pageNumber = number > 0 ? Number(number) : 1;
        const offset = (pageNumber - 1) * pageSize;
        let activeReportList = [], totalItems = 0;
        if (results && results.length > 0) {
            activeReportList = results.slice(offset, offset + pageSize)
            totalItems = results.length;
        }
        updateCurrentlyShowingItems(totalItems, pageSize, pageNumber);
        if(!filterApply){
            setReportList(result);
        }
        setActivePageData(activeReportList);
        setTotalItems(totalItems);
    }

    const onPageChangeHandler = (event, data) => {
        changeListDataOnPagination(pageSize, data.activePage);
        setPageNumber(data.activePage);
    }

    const closeModal = () => {
        setIsModalVisible(false);
    }

    const sortReportData = (reportListData, _sortKey, _orderType, filterApply = enableFilter, searchInputValue = searchReportInput) => {
        if (reportListData && reportListData.length > 0 && sortKey) {

            if (_orderType == "0") {
                if (_sortKey === SORTINGKEYS.ReportTitle || _sortKey === SORTINGKEYS.ReportType) {
                    reportListData.sort((a, b) => a[_sortKey].localeCompare(b[_sortKey]));
                } else if (_sortKey === SORTINGKEYS.ReportID) {
                    reportListData.sort(function (x, y) {
                        return x[_sortKey] - y[_sortKey];
                    });
                }
            }
            else {
                if (_sortKey === SORTINGKEYS.ReportID || _sortKey === SORTINGKEYS.RecordId) {
                    reportListData.sort(function (x, y) {
                        return y[_sortKey] - x[_sortKey];
                    });
                }else{
                    reportListData.reverse((a, b) => b[_sortKey].localeCompare(a[_sortKey]));
                }
                
            }
        }
        
        setSortKey({ sortOrder: _orderType, sortColumn: _sortKey});
        changeListDataOnPagination(pageSize, 1, reportListData, filterApply, searchInputValue);
    }

    const onSort = (event, sortKeyValue) => {
        let _orderType; // 0 for Ascending order, 1 for descending order
        if (event.target.attributes.getNamedItem('data-order')) {
            _orderType = event.target.attributes.getNamedItem('data-order').value;
        } else {
            _orderType = event.target.parentElement.attributes.getNamedItem('data-order').value;
        }
        const reportListData = reportList;
        let _sortkey = sortKeyValue.split(' ').join('');
        sortReportData(enableFilter ? storeSearchResult : reportListData, _sortkey, _orderType);
    }

    const handleOnKeyDown = (e) => {
        setSearchReportInput(e.target.value);
        if (e.which === 13) {
            setIsLoading(true);
            setFilterData([]);
            let filteredData = null;
            filteredData = reportList.filter(p => {
                if(String(p.Name.toLowerCase().trim()).includes(searchReportInput.toLowerCase().trim()) || String(p.BaseReportId).includes(searchReportInput.trim())){
                    return p;
                }
            });
            setEnableFilter(true, (newEnableFilter) =>{
                const tempSortKey = getTempSortKey(sortKey);
                setStoreSearchResult(filteredData);
                _.isEmpty(e.target.value) ? loadInitialList(e.target.value) : sortReportData(filteredData, tempSortKey.sortColumn, tempSortKey.sortOrder, newEnableFilter);
            });
            setIsLoading(false);
        }
    }

    const handleResultSelect = (e, { result }) => {
        setIsLoading(true);
        setFilterData([]);
        setEnableFilter(true, (newEnableFilter) =>{
            const tempSortKey = getTempSortKey(sortKey);
            setStoreSearchResult([result]);
            _.isEmpty(searchReportInput) ? loadInitialList() : sortReportData([result], tempSortKey.sortColumn, tempSortKey.sortOrder, newEnableFilter);
            setIsLoading(false);
        });
    }

    const handleSearchChange = (e, { value }) => {
        setSearchReportInput(value);
        setTimeout(() => {
            setIsLoading(true);
            let filteredData = null;
            if(!_.isEmpty(value)){
                filteredData = reportList.filter(p => {
                    if(String(p.Name.toLowerCase().trim()).includes(value.toLowerCase().trim()) || String(p.BaseReportId).includes(value.trim())){
                        return p;
                    }
                });
            }
            if(enableFilter){
                setEnableFilter(false);
            }
            setIsLoading(false);
            _.isEmpty(value) ? loadInitialList(value) : setFilterData(filteredData);
        }, 300)
    }

    const handleSearchIcon_Click = () => {
        setIsLoading(true);
        setFilterData([]);
        let filteredData = null;
        filteredData = reportList.filter(p => {
            if(String(p.Name.toLowerCase().trim()).includes(searchReportInput.toLowerCase().trim()) || String(p.BaseReportId).includes(searchReportInput.trim())){
                return p;
            }
        });
        setEnableFilter(true, (newEnableFilter) =>{
            const tempSortKey = getTempSortKey(sortKey);
            setStoreSearchResult(filteredData);
            _.isEmpty(searchReportInput) ? loadInitialList() : sortReportData(filteredData, tempSortKey.sortColumn, tempSortKey.sortOrder, newEnableFilter);
        });
        setIsLoading(false);
    }

    const updateSaveState = () => {
        setIsSaveReport(false);
    }

    const resultRenderer = ({ Name }) => <Label content={Name} />;

    const openAddReportModal = () => {
        if(_.isArray(typeReportList) && typeReportList.length === 0){
            getReportTypeListAction(dispatch, (response) => {
                setReportTypeList(response);
                setIsModalVisible(true);
             });
        }else{
            setIsModalVisible(true);
        }
       
    }

    const renderTableData = (data) => {
        if(data.length > 0){
            return data.map((item) => {
                return (
                    <tr key={item.BaseReportId}>
                        <td>{item.Name}</td>
                        <td>{item.BaseReportId}</td>
                        <td>{item?.ReportType}</td>
                        <td className="Email">
                        <div className='truncatedText'>
                            <a href={item.URL} target="_blank">
                                {item.URL}
                            </a>
                        </div>
                        </td>
                        { (props.userPermission.Delete || props.userPermission.Update) && 
                            <td className="action">
                                {props.userPermission.Update && <button className="edit-btn" data-testid="editButton"><i aria-hidden="true" className="pencil icon squareIcon"></i><span className="tooltop">Edit Report</span></button>}
                                {props.userPermission.Delete && <button className="delete-btn" data-testid="deleteButton"><i aria-hidden="true" className="trash icon squareIcon"></i><span className="tooltop">Delete Report</span></button>}
                            </td> 
                        }
                    </tr>
                )
            });
        }
    }
    
    return (
        <div className="homePage" data-testid="manageTemplatesCmp">
            <div className="ui manage-reports-wrap">
                <div className="headingTitle clearfix">
                    <h2>Manage Reports</h2>
                    {props.userPermission.Add &&
                    <button className="ui secondary button" onClick={openAddReportModal}><i aria-hidden="true" class="plus icon"></i>Add New Report</button> }
                    <div className='searchBx'>
                        <div className="ui icon input">
                        <Search
                            onKeyDown={handleOnKeyDown}
                            loading={isLoading}
                            onResultSelect={handleResultSelect}
                            onSearchChange={_.debounce(handleSearchChange, 500, { leading: true })}
                            results={filteredData}
                            value={searchReportInput}
                            resultRenderer={resultRenderer}
                            showNoResults={false}
                            noResultsMessage={"No results found."}
                            placeholder="Search Report by Report Title, Report ID"
                            {...props}
                        />
                        <span className="hiddenSearchIcon" onClick={handleSearchIcon_Click}></span>
                        </div>
                    </div>
                </div>
                <div className='tableWrapper' style={{ marginTop: '35px' }}>
                    <table className="customTable meetingTypeTable">
                        <thead>
                            <tr>
                                <TableHeader headerProps = { MEMBERSHIPREPORTSHEADERINFO} onClickProps = {onSort} resetHeader={isSaveReport} updateSaveState={updateSaveState}></TableHeader>
                                {(props.userPermission.Delete || props.userPermission.Update) &&<th className="Actions" data-order="0" style={{ width: '10%' }}>Actions</th>}
                            </tr>
                        </thead>
                        <tbody>
                            { renderTableData(activePageData) }
                        </tbody>
                    </table>
                    {(!_.isEmpty(searchReportInput) && activePageData.length <= 0) && (
                        <div className="noRecordMessage">No results found.</div>
                    )}
                    {(!reportList || (reportList && reportList.length === 0) && _.isEmpty(searchReportInput)) && (
                        <div className="noRecordMessage">No reports added.</div>
                    )}
                </div>
                { activePageData && activePageData.length > 0 &&
                    <section className="pagerWrap">
                        <div className="pager">
                            <PaginationShorthand defaultActivePage={pageNumber}
                                totalPages={Math.ceil(totalItems / pageSize)}
                                onPageChange={onPageChangeHandler} />
                        </div>
                
                        <div className="itemPerPage">
                            <RenderSelect onchange={(event, value) => onChangeNumberOfItemsPerPage(event, value)}
                                value={pageSize} options={ItemsPerPage} />
                            <span className="itemsPerPage">items per page</span>
                        </div>
                        <div className="totalPage">{currentlyShowingItems}</div>
                    </section>
                }
            </div>
            {isModalVisible && (
                <AddReportModal closeModal={closeModal} refreshListingPage={(isRecordAdded) => loadInitialList(searchReportInput, isRecordAdded)} typeReportList={typeReportList} {...props} />
            )}
        </div>
    );
    
}

export default MembershipReportsList;
