export const MEMBERSHIPREPORTSHEADERINFO = [
    { title: "Report Title", hasSorting: true, className: "ReportTitle", sortKey: "Name", orderBy: 0, orderByIcon: 0,isShow: true },
    { title: "Report ID",hasSorting: true, className: "ReportID", sortKey: "BaseReportId", orderBy: 0, orderByIcon: 0, isShow: true },
    { title: "Report Type", hasSorting: true, className: "ReportType", sortKey: "ReportType", orderBy: 0, orderByIcon: 0, isShow: true },
    { title: "Tableau URL", hasSorting: false, className: "url", orderBy: 0, orderByIcon: 0, isShow: true },
];

export const getTempSortKey = (sortKey) => {
    let tempSortKey = {};
    if(_.isEmpty(sortKey)){
        tempSortKey.sortColumn =  'Name';
        tempSortKey.sortOrder = '0';
    }else{
        tempSortKey.sortColumn = sortKey.sortColumn;
        tempSortKey.sortOrder = sortKey.sortOrder;
    }
    return tempSortKey;
};

export const FIELDSNAME = {
    ReportTitle:  'ReportTitle',
    ReportId: 'ReportID',
    ReportType: 'ReportType',
    TableauURL: 'TableauURL'
};

export const SORTINGKEYS = {
    ReportTitle: 'Name',
    ReportID: 'BaseReportId',
    ReportType: 'ReportType',
    RecordId: 'ReportId'
};

export const sortingAfterSave = { sortColumn: SORTINGKEYS.RecordId, sortOrder: 1};

export const GetSubmoduelOnModuleKey = 9;