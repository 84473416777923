import axios from 'axios';

export const apiUrls = {
    baseUrl: process.env.REACT_APP_API_URL,
    localBaseUrl: process.env.REACT_APP_API_URL,
    openApiUrl: process.env.REACT_APP_OPENAPI_URL,
    localOpenApiUrl: process.env.REACT_APP_OPENAPI_URL,
    live: {
        login: {
            login: {
                url: '/login',
                version: '/v1',
                requestType: 'POST'
            },
            logout: {
                url: '/logout',
                version: '/v1',
                requestType: 'POST'
            },
            authenticateByAzureAD: {
                url: '/authenticateByAzureAD',
                version: '/v1',
                requestType: 'GET'
            },
            getAzureADRefreshToken: {
                url: '/getAzureADRefreshToken',
                version: '/v1',
                requestType: 'POST'
            },
            authCookiesToken: {
                url: '/authCookiesToken',
                version: '/v1',
                requestType: 'POST'
            },
            initAzure: {
                url: '/initAzure',
                version: '/v1',
                requestType: 'POST'
            }
        },
        application: {
            getApplications: {
                url: '/application',
                version: '/v1',
                requestType: 'GET'
            },
            getApplicationByStatus: {
                url: '/application/status',
                version: '/v1',
                requestType: 'GET'
            },
            addApplication: {
                url: '/application',
                version: '/v1',
                requestType: 'POST'
            },
            getSecureKey: {
                url: '/application/getSecureKey',
                version: '/v1',
                requestType: 'GET'
            },
            editApplication: {
                url: '/application',
                version: '/v1',
                requestType: 'PUT'
            }
        },
        faq: {
            getFaqs: {
                url: '/faq/get',
                version: '/v1',
                requestType: 'GET'
            },
            getFaqDetails: {
                url: '/faq/get',
                version: '/v1',
                requestType: 'GET'
            },
            addFaq: {
                url: '/faq',
                version: '/v1',
                requestType: 'POST'
            },
            editFaq: {
                url: '/faq',
                version: '/v1',
                requestType: 'PUT'
            },
            resequenceFaqs: {
                version: '/v1',
                url: '/faq/resequence',
                requestType: 'PUT'
            },
            deleteFaq: {
                version: '/v1',
                url: '/faq',
                requestType: 'DELETE'
            }
        },
        membershipDocument: {
            getMemberShipDocumentsList: {
                url: '/document',
                version: '/v1',
                requestType: 'GET'
            },
            documentByStatus: {
                url: '/document/status',
                version: '/v1',
                requestType: 'GET'
            },

            addMembershipDocument: {
                url: '/document',
                version: '/v1',
                requestType: 'POST'
            },
            editMembershipDocument: {
                url: '/document',
                version: '/v1',
                requestType: 'PUT'
            },
            deleteMembershipDocument: {
                url: '/document',
                version: '/v1',
                requestType: 'DELETE'
            },
            getSignedUrl: {
                url: '/document/get-signed-url',
                version: '/v1',
                requestType: 'GET'
            },
            downloadFile: {
                url: '/document/download-file',
                version: '/v1',
                requestType: 'GET'
            }
        },
        membershipType: {
            getMembershipTypeList: {
                url: '/membershiptype/get',
                version: '/v1',
                requestType: 'GET'
            },
            getDocumentStatus: {
                url: '/document/status/open',
                version: '/v1',
                requestType: 'GET'
            },
            getApplicationStatus: {
                url: '/application/status/open',
                version: '/v1',
                requestType: 'GET'
            },
            membershipTypePost: {
                url: '/membershiptype/post',
                version: '/v1',
                requestType: 'POST'
            },
            membershipTypeGetPeriod: {
                url: '/membershiptype/get/period',
                version: '/v1',
                requestType: 'GET'
            },
            membershipTypeEdit: {
                url: '/membershiptype/get/',
                version: '/v1',
                requestType: 'GET'
            },
            membershipTypeSubmitEditDetails: {
                url: '/membershiptype/details',
                version: '/v1',
                requestType: 'PUT'
            },
            membershipTypeSubmitEditSetting: {
                url: '/membershiptype/setting',
                version: '/v1',
                requestType: 'PUT'
            },
            membershiptypeDeleteAppDoc: {
                url: '/membershiptypeDeleteAppDoc/post',
                version: '/v1',
                requestType: 'POST'
            },
            checkUniqueName: {
                url: '/membershiptype/get/checkalreadyexists',
                version: '/v1',
                requestType: 'GET'
            },
            CheckMembershipType: {
                url: '/membershiptype/get/MembershipType',
                version: '/v1',
                requestType: 'GET'
            }
        },

        classification: {
            getClassificationList: {
                url: '/memberclassification',
                version: '/v1',
                requestType: 'GET'
            },
            getClassificationDetails: {
                url: '/memberclassification',
                version: '/v1',
                requestType: 'GET'
            },
            addClassification: {
                url: '/memberclassification',
                version: '/v1',
                requestType: 'POST'
            },
            editClassification: {
                url: '/memberclassification',
                version: '/v1',
                requestType: 'PUT'
            },
            validateclassification: {
                url: '/validateclassification',
                version: '/v1',
                requestType: 'GET'
            },
            colorpicker: {
                url: '/memberclassification/colorpicker',
                version: '/v1',
                requestType: 'GET'
            },
            resequenceClassifications: {
                url: '/memberclassification/resequence',
                version: '/v1',
                requestType: 'PUT'
            }
        },
        membershipTemplate: {
            listMembershipTemplate: {
                url: '/membershiptemplate',
                version: '/v1',
                requestType: 'GET'
            },
            updateMembershipTemplate: {
                url: '/membershiptemplate',
                version: '/v1',
                requestType: 'PUT'
            },
            getAwsSigned: {
                url: '/membershiptemplate/getAwsSigned',
                version: '/v1',
                requestType: 'GET'
            },
            getDownloadSignedUrl: {
                url: '/membershiptemplate/getDownloadSignedUrl',
                version: '/v1',
                requestType: 'GET'
            }
        },
        officertitle: {
            getOfficerTitleList: {
                url: '/officertitle',
                version: '/v1',
                requestType: 'GET'
            },
            getOfficerTitleById: {
                url: '/officertitle/detail',
                version: '/v1',
                requestType: 'GET'
            },
            addOfficerTitle: {
                url: '/officertitle',
                version: '/v1',
                requestType: 'POST'
            },
            editOfficerTitle: {
                url: '/officertitle',
                version: '/v1',
                requestType: 'PUT'
            },
            editResponsibility: {
                url: '/officertitle/responsibility',
                version: '/v1',
                requestType: 'PUT'
            },
            getResponsibility: {
                url: '/officertitle/responsibility',
                version: '/v1',
                requestType: 'GET'
            },
            postResequence: {
                url: '/officertitle/sequencing',
                version: '/v1',
                requestType: 'POST'
            },
            getOfficerCommitteeTypeLevel: {
                url: '/officerCommitteeTypeLevel',
                version: '/v1',
                requestType: 'GET'
            },
            OfficerTitleCommitteeImpact: {
                url: '/OfficerTitleCommitteeImpact',
                version: '/v1',
                requestType: 'POST'
            },
            OfficerTitleAlreadyExists: {
                url: '/officertitle/OfficerTitleAlreadyExists',
                version: '/v1',
                requestType: 'GET'
            }
        },
        usermanage: {
            getRoleList: {
                url: '/role/Get',
                version: '/v1',
                requestType: 'GET'
            },
            getDefaultPrivilege: {
                url: '/defaultprivilege/Get',
                version: '/v1',
                requestType: 'GET'
            },
            getPrivilegeByRole: {
                url: '/privilegebyRole/Get',
                version: '/v1',
                requestType: 'GET'
            },
            getPrivilegeByUser: {
                url: '/privilegebyuser/Get',
                version: '/v1',
                requestType: 'GET'
            },
            getUserDetail: {
                url: '/userdetail/Get',
                version: '/v1',
                requestType: 'GET'
            },
            usermanagement: {
                url: '/usermanagement',
                version: '/v1',
                requestType: 'POST'
            },
            getuserPrivilege: {
                url: '/userPrivilege',
                version: '/v1',
                requestType: 'GET'
            },
            getuserList: {
                url: '/usermanagement/userlist',
                version: '/v1',
                requestType: 'GET'
            },
            putuserPrivilege: {
                url: '/usermanagement/updatePrivileges',
                version: '/v1',
                requestType: 'PUT'
            },
            getUserListOnSearch: {
                url: '/usermanagement/searchuser/1',
                version: '/v1',
                requestType: 'GET'
            },
            getUserValidate: {
                url: '/usermanagement/validateuser',
                version: '/v1',
                requestType: 'GET'
            },
            updateUserPassword: {
                url: '/usermanagement/updateUserPassword',
                version: '/v1',
                requestType: 'PUT'
            }
        },
        committeeType: {
            getCommitteeTypeList: {
                url: '/committeetype',
                version: '/v1',
                requestType: 'GET'
            },
            getMembershipType: {
                url: '/membershiptype/get/open',
                version: '/v1',
                requestType: 'GET'
            },
            getClassification: {
                url: '/memberclassification/open/committee',
                version: '/v1',
                requestType: 'GET'
            },
            postCommitteeType: {
                url: '/committeetype',
                version: '/v1',
                requestType: 'POST'
            },
            putCommitteeType: {
                url: '/committeetype',
                version: '/v1',
                requestType: 'PUT'
            },
            getOperator: {
                url: '/committeetype/operator',
                version: '/v1',
                requestType: 'GET'
            },
            getApplicationPrivilege: {
                url: '/committeetype/applicationprivilege',
                version: '/v1',
                requestType: 'GET'
            },
            checkUniqueName: {
                url: '/committeetype/validatecommitteetype',
                version: '/v1',
                requestType: 'GET'
            },
            checkLevelAssociatedWithOffice: {
                url: '/committeetype/checkLevelAssociatedWithOffice',
                version: '/v1',
                requestType: 'GET'
            },
            getCommitteeTypeDetails: {
                url: '/committeetype/detail',
                version: '/v1',
                requestType: 'GET'
            },
            getCommitteeTypeDetailForEdit: {
                url: '/committeetype/editDetail',
                version: '/v1',
                requestType: 'GET'
            },
            validateMembershipType: {
                url: '/committeetype/validateMembershipType',
                version: '/v1',
                requestType: 'POST'
            }

        },
        meetingType: {
            getMeetingTypeList: {
                url: '/meetingtype',
                version: '/v1',
                requestType: 'GET'
            },
            postMeetingType: {
                url: '/meetingtype',
                version: '/v1',
                requestType: 'POST'
            },
            putMeetingType: {
                url: '/meetingtype',
                version: '/v1',
                requestType: 'PUT'
            }
        },
        BOSVolume: {
            getBosVolumeList: {
                url: '/bosVolume',
                version: '/v1',
                requestType: 'GET'
            },
            postBosVolume: {
                url: '/bosVolume',
                version: '/v1',
                requestType: 'POST'
            },
            putBosVolume: {
                url: '/bosVolume',
                version: '/v1',
                requestType: 'PUT'
            },
            checkBosVolumeCommitteeAssociation: {
                url: '/bosVolume/bosVolumeCommitteeAssociation',
                version: '/v1',
                requestType: 'POST'
            },
            deleteBosVolume: {
                url: '/bosVolume',
                version: '/v1',
                requestType: 'DELETE'
            }
        },
        openAPI: {
            flagFeature: {
              url: "/master/featureflag",
              version: "/v1",
              requestType: "GET",
            }
        },
        membershipReports: {
            listMembershipReport: {
                url: '/manageReport',
                version: '/v1',
                requestType: 'GET'
            },
            addMembershipReport: {
                url: '/manageReport',
                version: '/v1',
                requestType: 'POST'
            },
            listMembershipTypeList: {
                url: '/usermanagement/GetSubmoduelOnModule',
                version: '/v1',
                requestType: 'GET'
            }
            
        },
    },
    local: {
        login: {
            login: {
                url: '/login',
                version: '/v1',
                requestType: 'GET'
            }
        }
    }
}

export const callAPI = (url, method, postData, isHeader, cb) => {
    let axiosObj = {
        baseURL: '',
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'Access-Control-Allow-Origin': '*',
        }
    }
    method = method || "get";

    axios[method](url, postData, axiosObj).then(
        res => {
            cb && cb(res);
        },
        err => {
            cb && cb(null);
        }
    ).catch(error => {
        cb && cb(null);
    });
};

  
function getApiUrl(key, name, isOpenAPICall) { 
    let url
    const baseURL= isOpenAPICall === true ? apiUrls.openApiUrl : apiUrls.baseUrl;
    if (baseURL !== null) {
        if (apiUrls.live[key] && apiUrls.live[key][name]) {
            url = baseURL + apiUrls.live[key][name]['version'] + apiUrls.live[key][name]['url']
        } else if (apiUrls.local[key] && apiUrls.local[key][name]) {
            const localURL = isOpenAPICall === true ? apiUrls.localOpenApiUrl : apiUrls.openApiUrl;
            url = localURL + apiUrls.local[key][name]['version'] + apiUrls.local[key][name]['url']
        }
    } else if (apiUrls.local[key] && apiUrls.local[key][name]) {
        const localURL = isOpenAPICall === true ? apiUrls.localOpenApiUrl : apiUrls.openApiUrl;
        url = localURL + apiUrls.local[key][name]['version'] + apiUrls.local[key][name]['url']
    }
    return url;
}

export default getApiUrl;
