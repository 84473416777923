import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Grid, Icon } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import { useDispatch } from "react-redux";
import RenderTextArea from 'components/atoms/RenderTextarea'
import RenderInputField from "components/atoms/Input";
import _ from 'lodash';
import SelectDropdown from "components/atoms/SelectDropdown";
import { getMessage } from "helpers/utilCommon";
import { addMembershipReportsAction } from 'actions/Membership/membershipReportsAction';
import { FIELDSNAME } from 'models/Membership/membershipReportsModel';

const AddMembershipReportModal = (props) => {
    const [duplicateErrors, setDuplicateErrors] = useState({});
    const [isDuplicateError, setIsDuplicateError] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        getReportsDetails(props.initialValues);
      }, []);

    const { ReportTitle, ReportId, ReportType, TableauURL } = FIELDSNAME;
    
    const setTypeOptionsData = () => {
        let response = []
        if ((props.typeReportList || []).length > 0) {
            props.typeReportList.map((res) => {
                let result = {};
                const { SubModuleId, SubModuleName } = res;
                result.key = SubModuleId;
                result.value = SubModuleId;
                result.text = SubModuleName;
                response.push(result);
            });
        }
        return response;
    }
    
    const getReportsDetails = (initialState) => {
        if (initialState) {
            props.change([ReportTitle], initialState.ReportTitle)
            props.change([ReportId], initialState.ReportID)
            props.change([ReportType], initialState.ReportType)
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        if(isDuplicateError){
            let errors = duplicateErrors
            for (const [key, value] of Object.entries(duplicateErrors)) {
                if(name === key ){
                    delete errors[key];
                }
            }
            setDuplicateErrors(errors);
        }

        if(isDuplicateError && _.isEmpty(duplicateErrors)){
            setIsDuplicateError(false);
        }
        props.change([name], value);
        
    }

    const checkIsDuplicate = (fieldName) => {
        let isDuplicate = false;
        if(!_.isEmpty(duplicateErrors)){
            for (const [key, value] of Object.entries(duplicateErrors)) {
                if(key === fieldName ){
                    isDuplicate = true;
                }
            }
        }
        return isDuplicate;
    }

    const handleForm = (values) => {
        if(!isDuplicateError && _.isEmpty(duplicateErrors)){
            let data = {
                Name: values.ReportTitle,
                BaseReportId: values.ReportID,
                URL: values.TableauURL,
                Type: values.ReportType,
                CategoryId: 1
              }
            addMembershipReportsAction(dispatch, data, (result) => {
                if(result.status){
                    resetForm();
                    props.closeModal();
                    //true is passing because when record added and sort the records with recordid in desc order
                    props.refreshListingPage(true);
                }else{
                    setIsDuplicateError(true);
                    let dupError = {};
                    for (const [key, value] of Object.entries(result.content)) {
                        if(Number(key) === 2802 ){
                            dupError.ReportTitle = value;
                        }
                        else if(Number(key) === 2806 ){
                            dupError.ReportID = value;
                        }
                        else if(Number(key) === 2808 ){
                            dupError.TableauURL = value;
                        }
                    }

                    setDuplicateErrors(dupError);
                }
            });
        }
    };

    const resetForm = () => {
        props.reset();
    }
   const handleKeyPress = event => {
        if(!/[0-9a-zA-Z ]$/.test(event.key)){
            event.preventDefault();
        }
    };

    const handleKeyPressForNumber = event => {
        if(!/[0-9]$/.test(event.key)){
            event.preventDefault();
        }
    };

    const { handleSubmit, initialValues, submitting } = props;

    return (
        <Modal open='true' className="tiny" data-testid="editTemplateModal">
            <Form size='large' onSubmit={handleSubmit(handleForm)} noValidate>
                <Modal.Header>Add New Report <i onClick={() => props.closeModal()} class="close">&#x2716;</i></Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Grid columns='equal'>
                        <Grid.Row>
                            <Grid.Column>
                                <Field 
                                    type="text"
                                    label="Report Title"
                                    name={ReportTitle}
                                    className='ui mini fluid input'
                                    required
                                    component={RenderInputField}
                                    placeholder="Enter Report Title"
                                    maxLength="200"
                                    onKeyPress={handleKeyPress}
                                    onChange={handleChange}
                                    isDuplicate={checkIsDuplicate(ReportTitle)}
                                    duplicateError={getMessage(props.messageCodes, '2802.text')} />
                            </Grid.Column> 
                            <Grid.Column>
                                <Field 
                                    type="text"
                                    label="Report ID"
                                    name={ReportId}
                                    className='ui mini fluid input'
                                    required
                                    component={RenderInputField}
                                    placeholder="Enter Report ID"
                                    maxLength="20" 
                                    onKeyPress={handleKeyPressForNumber}
                                    onChange={handleChange}
                                    isDuplicate={checkIsDuplicate(ReportId)}
                                    duplicateError={getMessage(props.messageCodes, '2806.text')} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className="twoColInput">
                                <label className="label"><i aria-hidden="true" class="asterisk  icon"></i>Report Type</label>
                                <Field
                                    name={ReportType}
                                    label='Select'
                                    className='ui mini fluid input'
                                    required
                                    component={SelectDropdown}
                                    options={setTypeOptionsData()} />
                            </Grid.Column> 
                        </Grid.Row>
                        
                            <Grid.Row>
                                <Grid.Column>
                                    <Field 
                                        name={TableauURL}
                                        type="url"
                                        placeholder="Enter Tableau URL"
                                        required
                                        component={RenderTextArea} label="Tableau URL"
                                        maxLength="500"
                                        onChange={handleChange}
                                        isDuplicate={checkIsDuplicate(TableauURL)} 
                                        duplicateError={getMessage(props.messageCodes, '2808.text')} 
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className="actions">
                                    <Button primary type="submit" disabled={submitting}>
                                        Save
                                    </Button>
                                    <Button type="button" secondary className="cancel" onClick={() => props.closeModal()}>
                                        Cancel
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>

                        </Grid>

                    </Modal.Description>
                </Modal.Content>
            </Form>
        </Modal>
    );
}

const validateHandler = (values, props) => {
    const errors = {};
    if (!values.ReportTitle) {
        errors.ReportTitle = getMessage(props.messageCodes, '7166.text');
    }
    else if(!/^[a-zA-Z0-9_ ]*$/i.test(values.ReportTitle)){
        errors.ReportTitle = 'Only accepts alphanumeric';
    }
    if (!values.ReportID) {
        errors.ReportID = getMessage(props.messageCodes, '7167.text');
    }
    else if(!/^[0-9]*$/i.test(values.ReportID)){
        errors.ReportID = 'Only numbers allowed.';
    }
    if (!values.ReportType) {
        errors.ReportType = getMessage(props.messageCodes, '7168.text');
    }
    if (!values.TableauURL) {
        errors.TableauURL = getMessage(props.messageCodes, '7169.text');
    }
    return errors;
};

export default (reduxForm({
    form: 'addMembershipReportModal',
    validate: validateHandler
})(AddMembershipReportModal));